import {TIME} from "constants/time";
import {TrackerGroupByDate} from "../../customTrackerGraphV2Types";
import {GRAPH_TIME_VIEW_VALUE} from "./graphV2Config";

// divide by Y_AXIS - 1 because of there are only 5 lines that have value
export const getYTickValues = (yLines: number, maxYData: number): number[] => {
	return Array.from({length: yLines}, (value, index) => index * Math.ceil(maxYData / (yLines - 1)));
};

// number x tick will show depend on range date,
// if date range 5 - 9 => it will only show 3 ticks, to keep space consistency of each tick
// else will show 4 ticks
export const getTotalXTick = (rangeDateData: number): number => {
	if (rangeDateData > 4 && rangeDateData < 10) return 3;
	return 4;
};

export const getEndDate = (selectedTimeView: GRAPH_TIME_VIEW_VALUE, startDate: Date): Date => {
	if (selectedTimeView === GRAPH_TIME_VIEW_VALUE.WEEK && startDate) {
		const curr = new Date(startDate);
		return new Date(curr.setTime(curr.getTime() + 6 * TIME.MILLISECONDS_IN_24HOURS));
	}
	if (selectedTimeView === GRAPH_TIME_VIEW_VALUE.MONTH && startDate) {
		const curr = new Date(startDate);
		const currYear = curr.getFullYear();
		const currMonth = curr.getMonth();
		return new Date(currYear, currMonth + 1, 0);
	}
	return new Date();
};

/**
 * this func will return list of date in string Type
 * it will filter data based on start date, end date and selectedTimeView
 * if selectedTimeView === GRAPH_TIME_VIEW_VALUE.ALL_TIME, it won't filter the date
 **/
export const getActiveStringDates = (
	trackerData: TrackerGroupByDate,
	selectedTimeView: GRAPH_TIME_VIEW_VALUE,
	startDate: Date,
	endDate: Date,
): string[] => {
	const dateData = Object.keys(trackerData);
	const dateDataSorted = dateData.sort((a, b) => {
		return transformRegistrationDateFromString(a).getTime() - transformRegistrationDateFromString(b).getTime();
	});

	return dateDataSorted.filter((dateString) => {
		if (
			(selectedTimeView === GRAPH_TIME_VIEW_VALUE.WEEK || selectedTimeView === GRAPH_TIME_VIEW_VALUE.MONTH) &&
			startDate
		) {
			const curr = transformRegistrationDateFromString(dateString).getTime();
			const startDateTime = startDate.getTime();
			const endDateTime = endDate.getTime();
			return curr >= startDateTime && curr <= endDateTime;
		}
		return dateString;
	});
};

/**
 * Transform Registration Date from string,
 * each browser has its own type which can be changed from string to date
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse
 * setFullYear, setMonth, and setDate can run smooth in all browser
 */

export const transformRegistrationDateFromString = (stringDate: string): Date => {
	const dateResult = new Date();
	const splittedDate = stringDate.split("/");
	dateResult.setFullYear(parseInt(splittedDate[2]));
	dateResult.setMonth(parseInt(splittedDate[1]));
	dateResult.setDate(parseInt(splittedDate[0]));
	dateResult.setHours(0);
	dateResult.setMinutes(0);
	return dateResult;
};
