import {useMemo} from "react";
import strTranslation from "../../../../assets/lang/strings";
import localization from "../../../../localization/Localization";
import {NDColors} from "../../../../material-ui/NDColors";
import {
	GraphV2Type,
	IGraphConfig,
	IGraphV2Data,
	IUseGraphData,
	TrackerGroupByDate,
} from "../../customTrackerGraphV2Types";
import {GRAPH_TIME_VIEW_VALUE} from "./graphV2Config";
import {getActiveStringDates, getEndDate, transformRegistrationDateFromString} from "./graphV2ConfigHelper";
import {useGetXTickValues, useGetYTickValues} from "./graphV2TickHooks";

const defaultGraphConfig = {
	color: NDColors.ORANGE,
	id: strTranslation.CUSTOM_TRACKER.chart.average_duration,
};

export interface IAverageDurationGraphConfig {
	color: string;
	id: string;
}

interface IAverageDurationGraphConfigWithMeasure extends IAverageDurationGraphConfig {
	measureKey: string;
}

const mapConfigToMeasure = (
	measures: string[],
	graphConfig: IAverageDurationGraphConfig[],
): IAverageDurationGraphConfigWithMeasure[] => {
	return measures.map((measure, i) => {
		return {
			measureKey: measure,
			color: graphConfig ? graphConfig[i].color : defaultGraphConfig.color,
			id: graphConfig ? graphConfig[i].id : defaultGraphConfig.id,
		};
	});
};

// minimum value of Y scale
const HIGHEST_Y_SCALE: number = 60;
// always return 6 lines start from 0
const Y_LINES: number = 6;

export const averageDurationConfig = (
	measures: string[],
	graphConfig?: IAverageDurationGraphConfig[],
): IGraphConfig => {
	return {
		graphType: GraphV2Type.AVERAGE_DURATION,
		graphName: localization.formatMessage(strTranslation.CUSTOM_TRACKER.chart.average_duration),
		yLegend: localization.formatMessage(strTranslation.CUSTOM_TRACKER.chart.average_duration),
		xLegend: localization.formatMessage(strTranslation.TIME.date),
		useGraphData: (
			trackerData: TrackerGroupByDate,
			trackerName: string,
			graphType: GraphV2Type,
			startDate: Date,
			selectedTimeView: GRAPH_TIME_VIEW_VALUE,
		): IUseGraphData => {
			const endDate = getEndDate(selectedTimeView, startDate);

			// data
			const graphData: IGraphV2Data[] = useMemo(() => {
				const activeDates: string[] = getActiveStringDates(trackerData, selectedTimeView, startDate, endDate);
				const measureWithConfig = mapConfigToMeasure(measures, graphConfig);
				const data = measureWithConfig.map((measure) => {
					return {
						id: measure.id,
						color: measure.color,
						data: activeDates
							.map((date) => {
								const xDate = transformRegistrationDateFromString(date);
								const totalDuration = trackerData[date].reduce((total, tracker) => {
									const duration =
										tracker.value.measures[measure.measureKey]?.sensorData?.value?.duration ?? 0;
									const durationInMin = duration / 60;
									return total + durationInMin;
								}, 0);

								const lengthData = trackerData[date].length;
								const averageDuration = totalDuration / lengthData;
								return {
									x: xDate,
									y: Number(averageDuration.toPrecision(2)),
								};
							})
							.filter((data) => data.y > 0),
					};
				});
				// filter graph that contains zero value
				// https://github.com/plouc/nivo/issues/1854
				return data.filter((res) => res.data.length > 0);
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [trackerData, trackerName, graphType, selectedTimeView, startDate]);

			const yTickValues: number[] = useGetYTickValues(graphData, HIGHEST_Y_SCALE, Y_LINES);

			const xTickValues: Date[] = useGetXTickValues(graphData, selectedTimeView, startDate, endDate);

			return {
				data: graphData,
				yMax: yTickValues[yTickValues.length - 1],
				yTickValues,
				xMin: xTickValues[0],
				xMax: xTickValues[xTickValues.length - 1],
				xTickValues,
			};
		},
	};
};
