import {put, select, call} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import moment from "moment";
import {NotFoundError, PsychoEducationItem} from "@sense-os/goalie-js";

import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {toastActions} from "../../../toaster/redux/toastAction";
import loc from "../../../localization/Localization";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {UIAction} from "redux/UI/UIAction";
import strTranslation from "../../../assets/lang/strings";

import {psychoEducationActions} from "../redux/psychoEducationActions";
import psychoEducationSDK, {getPsychoEducationDetails} from "../helpers/psychoEducationSDK";
import {getReminderDateTime} from "../helpers/psychoEducationHelpers";
import {getPsychoEducationData, getRemovedArticleIds, getPsychoEducationId} from "../redux/psychoEducationSelector";
import {CoreTrackerId} from "../../utils/coreTracker";
import {onAutoEnableCoreTracker} from "../../customTracker/helpers/onAutoEnableCoreTracker";

const log = createLogger("psychoEducationSaga", SentryTags.PsychoEducation);

export function* savePsychoEducation(action: ActionType<typeof psychoEducationActions.savePsychoEducation.request>) {
	const userId = action.payload.userId || null;
	const formValues = action.payload.formValues;
	const psychoEducationId: string = yield select(getPsychoEducationId);
	const eventViewData: PsychoEducationItem = yield select(getPsychoEducationData);
	const isEditing: boolean = !!psychoEducationId;
	try {
		const token: string = yield call(getSessionId);
		// Set `remindedDateTime` to `null` if `reminderToggle` is not enabled
		const reminderDateTime: Date = yield call(getReminderDateTime, formValues.reminderToggle && formValues);
		// Show warning toast when `reminderDateTime` is before creation date
		if (moment(reminderDateTime).isBefore()) {
			yield put(
				toastActions.addToast({
					message: loc.formatMessage(strTranslation.VALIDATION.reminder_must_be_in_future),
					type: "warning",
				}),
			);
			yield put(psychoEducationActions.savePsychoEducation.failure(new Error()));
			return;
		}
		if (isEditing) {
			// Update psycho education if `eventViewData` is not null
			yield apiCallSaga(psychoEducationSDK.updatePsychoEducation, token, {
				id: eventViewData.id,
				patient: eventViewData.patient,
				title: formValues.title,
				description: formValues.description,
				expiresAt: formValues.dueDate,
				remindAt: reminderDateTime,
			});
			const removedArticleIds: number[] = yield select(getRemovedArticleIds);

			if (removedArticleIds.length > 0) {
				// Remove psycho education articles
				yield put(
					psychoEducationActions.removeArticles.request({
						psychoEducationId: eventViewData.id,
						articleIds: removedArticleIds,
					}),
				);
			} else {
				// Update psycho education articles
				yield put(
					psychoEducationActions.updateArticles.request({
						psychoEducationId: eventViewData.id,
						articles: formValues.articles,
					}),
				);
			}
		} else {
			// Create new psycho education
			const response: PsychoEducationItem = yield apiCallSaga(psychoEducationSDK.createPsychoEducation, token, {
				patient: userId,
				title: formValues.title,
				description: formValues.description,
				expiresAt: formValues.dueDate,
				remindAt: reminderDateTime,
			});

			// Create new psycho education articles
			yield put(
				psychoEducationActions.saveArticles.request({
					psychoEducationId: response.id,
					articlesBody: formValues.articles,
				}),
			);

			/**
			 * Auto enable psychoeducation if it's disabled after create a new registration
			 */
			yield call(onAutoEnableCoreTracker, {
				userId,
				coreTrackerId: CoreTrackerId.PSYCHOEDUCATION,
			});
		}

		yield put(psychoEducationActions.savePsychoEducation.success({userId}));
		yield put(psychoEducationActions.closeForm());

		yield put(
			toastActions.addToast({
				message: loc.formatMessage(
					isEditing
						? strTranslation.GRAPHS.new_event.psycho_education.toast.update.success
						: strTranslation.GRAPHS.new_event.psycho_education.toast.create.success,
				),
				type: "info",
			}),
		);
	} catch (err) {
		log.captureException(err);
		yield put(psychoEducationActions.savePsychoEducation.failure(err));

		if (err instanceof NotFoundError) {
			yield put(
				toastActions.addToast({
					message: loc.formatMessage(strTranslation.GRAPHS.event_detail.psycho_education.toast.not_found),
					type: "error",
				}),
			);
		} else {
			yield put(
				toastActions.addToast({
					message: loc.formatMessage(strTranslation.GRAPHS.new_event.psycho_education.toast.error),
					type: "error",
				}),
			);
		}
	}
}

export function* handleOpenForm() {
	// Close chat box whenever Psycho education form is opened
	yield put(UIAction.closeChatBox());
}

export function* fetchPsychoEducation(action: ActionType<typeof psychoEducationActions.fetchPsychoEducation.request>) {
	const {id} = action.payload;

	const token = yield call(getSessionId);
	try {
		const psychoEducations: PsychoEducationItem = yield apiCallSaga(getPsychoEducationDetails, token, id);
		yield put(psychoEducationActions.fetchPsychoEducation.success({psychoEducationData: psychoEducations}));
	} catch (err) {
		log.captureException(err);
		yield put(psychoEducationActions.fetchPsychoEducation.failure(err));
	}
}
