import {TrackerItem} from "@sense-os/goalie-js";
import {call, put, select} from "redux-saga/effects";
import {getCoreTrackerList} from "../redux/customTrackerSelectors";
import {customTrackerActions} from "../redux/customTrackerActions";
import featureFlags from "../../../featureFlags/FeatureFlags";
import {CoreTrackerId} from "../../utils/coreTracker";

interface OnAutoEnableCoreTrackerParams {
	userId: number;
	coreTrackerId: CoreTrackerId;
}

function* getCoreTrackerByTrackerId(coreTrackerId: number) {
	const coreTrackerList: TrackerItem[] = yield select(getCoreTrackerList);
	const selectedTracker = coreTrackerList.find((tracker) => {
		return tracker.id === coreTrackerId;
	});

	return selectedTracker;
}

/**
 * Automatic enable core tracker, if the core tracker is disabled
 * only can be used on saga action
 */
export function* onAutoEnableCoreTracker(params: OnAutoEnableCoreTrackerParams) {
	const {userId, coreTrackerId} = params;

	// only used if core tracker feature flag is enabled
	if (featureFlags.coreTracker) {
		const selectedTracker: TrackerItem = yield call(getCoreTrackerByTrackerId, coreTrackerId);
		// check if the tracker status is disabled
		if (!selectedTracker?.isEnabled) {
			yield put(
				customTrackerActions.saveManageTrackers.request({
					userId,
					isEnabled: true,
					trackerId: coreTrackerId,
					trackers: [],
				}),
			);
		}
	}
}
