import {
	InterventionCustomTrackerList,
	InterventionCustomTrackerListItem,
	InterventionCustomTrackerParams,
} from "@sense-os/goalie-js";
import {call, put} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import createLogger from "../../../logger/createLogger";
import {getCustomTrackerSummaryLists, transformCustomTrackerSummary} from "../helpers/interventionCustomTrackerHelper";
import {interventionCustomTrackerActions} from "../redux/interventionCustomTrackerActions";
import {isTrackerSupported} from "../../../tracker/customTracker/helpers/trackerChartConfig";

const log = createLogger("fetchCustomTrackers", SentryTags.Intervention);

export function* fetchCustomTrackers(
	action: ActionType<typeof interventionCustomTrackerActions.fetchCustomTrackers.request>,
) {
	const {hashId} = action.payload;

	try {
		const token: string = yield call(getSessionId);
		const params: InterventionCustomTrackerParams = {
			patient: hashId,
		};

		const response: InterventionCustomTrackerList = yield apiCallSaga(getCustomTrackerSummaryLists, token, params);

		const {count, results} = response;

		// since the `/trackers/` also return the 'default trackers', the response is needed to be filtered
		const supportedCustomTrackers: InterventionCustomTrackerListItem[] = results.filter((resultItem) => {
			return isTrackerSupported(resultItem.sensorName);
		});

		// trackerName converted using localization
		const formattedCustomTrackers = supportedCustomTrackers.map(transformCustomTrackerSummary);

		yield put(
			interventionCustomTrackerActions.fetchCustomTrackers.success({
				customTrackers: formattedCustomTrackers,
				totalCustomTrackers: count,
			}),
		);
	} catch (error) {
		log.captureException(error);
		yield put(interventionCustomTrackerActions.fetchCustomTrackers.failure({error}));
	}
}
