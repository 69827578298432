import {call, put, select, takeEvery} from "@redux-saga/core/effects";
import {Organization, OrgFormKey, TreatmentComplaint, TreatmentPersonnel, TreatmentType} from "@sense-os/goalie-js";
import {LoadingState} from "constants/redux";
import {AppState} from "redux/AppState";
import {ActionType, getType} from "typesafe-actions";
import strTranslation from "../../assets/lang/strings";
import {AuthUser} from "../../auth/authTypes";

import {getSessionId} from "../../auth/helpers/authStorage";
import {getAuthUser} from "../../auth/redux/authSelectors";
import {ContactsMap} from "../../contacts/contactTypes";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import createLogger from "../../logger/createLogger";
import {getOrganizationById} from "../../organizations/redux/organizationSelectors";
import {toastActions} from "../../toaster/redux";

import {treatmentStatusActions} from "../redux/treatmentStatusActions";
import {
	getAllClientComplaints,
	getAllTreatmentTypes,
	getClientStatus,
	getEditedTreatment,
} from "../redux/treatmentStatusSelectors";
import treatmentStatusSdk from "../treatmentStatusSdk";
import {SdkTreatment, Treatment, TreatmentFromForm, TreatmentPersonnelFromForm} from "../treatmentStatusTypes";
import {automaticTreatmentCreationSaga} from "./automaticTreatmentCreationSaga";

const log = createLogger("TreatmentStatus - create or update treatment saga", SentryTags.TreatmentStatus);

function* registerTreatmentTypeIfNeeded(treatmentTypes: string[]) {
	// we need to filter undefined value from list,
	// undefined value comes from bug input component,
	const filteredUndefinedTreatment = treatmentTypes.filter((treatmentType) => treatmentType);
	if (filteredUndefinedTreatment.length > 0) {
		const currentTreatmentTypes: TreatmentType[] = yield select(getAllTreatmentTypes);
		// this will contain new treatmentType that has not registered yet in backend
		const newTreatmentTypes = filteredUndefinedTreatment.filter((t) =>
			currentTreatmentTypes.every((curr) => curr.value !== t),
		);

		const token: string = getSessionId();
		for (let idx = 0; idx < newTreatmentTypes.length; idx++) {
			const newlyRegisteredTreatmentTypes: TreatmentType = yield apiCallSaga(
				treatmentStatusSdk.createTreatmentType,
				token,
				newTreatmentTypes[idx],
			);
			yield put(treatmentStatusActions.addTreatmentType(newlyRegisteredTreatmentTypes));
		}
	}
}

function* removeTreatmentTypeIfNeeded(treatment: SdkTreatment, treatmentTypes: string[]) {
	const currentTreatment: Treatment = yield select(getEditedTreatment);
	if (!currentTreatment || currentTreatment.treatmentTypes.length === 0) return;

	const deletedTreatmentTypes = currentTreatment.treatmentTypes.filter((t) =>
		treatmentTypes.every((curr) => curr !== t.value),
	);

	const token: string = getSessionId();
	for (let idx = 0; idx < deletedTreatmentTypes.length; idx++) {
		try {
			yield apiCallSaga(
				treatmentStatusSdk.removeATreatmentTypeOfTreatment,
				token,
				treatment.id,
				deletedTreatmentTypes[idx].id,
			);
		} catch (error) {
			// For now, just ignore whenever portal failed to remove it.
			// This removing function will only be called whenever therapist
			// edit a treatment, and if we failed to remove this data,
			// let's just continue with the whole edit treatment instead of stopping the process.
			// The user can try to remove this data again later.
			log.captureException(error);
		}
	}
}

/**
 * Store in backend, the treatment types information of a treatment.
 * First, if there's a custom / portal's user specific type information,
 * we register this custom type to backend.
 * Next, we remove previously set types, and last,
 * we store the newly set types.
 */
function* attachTreatmentType(treatment: SdkTreatment, patientId: number, treatmentTypesStr: string[]) {
	yield call(registerTreatmentTypeIfNeeded, treatmentTypesStr);
	yield call(removeTreatmentTypeIfNeeded, treatment, treatmentTypesStr);

	const allTreatmentTypes: TreatmentType[] = yield select(getAllTreatmentTypes);
	const selectedTreatmentTypes = treatmentTypesStr.map((t) => allTreatmentTypes.find((curr) => curr.value === t));

	const token: string = getSessionId();

	// we need to filter undefined value from list,
	// undefined value comes from bug input component,
	const filteredSelectedTreatmentTypes = selectedTreatmentTypes.filter(
		(selectedTreatmentType) => selectedTreatmentType,
	);
	const treatmentTypeIds = filteredSelectedTreatmentTypes.map((t) => t.id);
	if (filteredSelectedTreatmentTypes.length > 0) {
		yield apiCallSaga(treatmentStatusSdk.addTreatmentTypesToTreatment, token, treatment.id, treatmentTypeIds);
		yield put(treatmentStatusActions.setTreatmentTypesOfTreatment(patientId, selectedTreatmentTypes));
	}
}

function* registerTreatmentComplaintsIfNeeded(treatmentComplaints: string[]) {
	const allComplaints: TreatmentComplaint[] = yield select(getAllClientComplaints);
	const newComplaints = treatmentComplaints.filter((t) => allComplaints.every((curr) => curr.value !== t));

	const token: string = getSessionId();
	for (let idx = 0; idx < newComplaints.length; idx++) {
		const newlyRegisteredComplaint: TreatmentComplaint = yield apiCallSaga(
			treatmentStatusSdk.createTreatmentComplaint,
			token,
			newComplaints[idx],
		);
		yield put(treatmentStatusActions.addClientComplaint(newlyRegisteredComplaint));
	}
}

function* removePrevTreatmentComplaints(treatment: SdkTreatment) {
	const currentTreatment: Treatment = yield select(getEditedTreatment);
	if (!currentTreatment || currentTreatment.clientComplaints.length === 0) return;

	const deletedComplaints = currentTreatment.clientComplaints;
	const token: string = getSessionId();
	for (let idx = 0; idx < deletedComplaints.length; idx++) {
		try {
			yield apiCallSaga(
				treatmentStatusSdk.removeAComplaintOfTreatment,
				token,
				treatment.id,
				deletedComplaints[idx].id,
			);
		} catch (error) {
			// For now, just ignore whenever portal failed to remove it.
			// This removing function will only be called whenever therapist
			// edit a treatment, and if we failed to remove this data,
			// let's just continue with the whole edit treatment instead of stopping the process.
			// The user can try to remove this data again later.
			log.captureException(error);
		}
	}
}

/**
 * Store in backend, the treatment complaint information of a treatment.
 * First, if there's a custom / portal's user specific complaint information,
 * we register this custom complaint to backend.
 * Next, we remove previously set complaints, and last,
 * we store the newly set complaints.
 */
function* attachTreatmentComplaint(
	treatment: SdkTreatment,
	patientId: number,
	treatmentComplaintsStr: string[],
	mainComplaint?: string,
) {
	yield call(registerTreatmentComplaintsIfNeeded, treatmentComplaintsStr);
	yield call(removePrevTreatmentComplaints, treatment);

	const allComplaints: TreatmentComplaint[] = yield select(getAllClientComplaints);
	const selectedComplaints = treatmentComplaintsStr.map((t) => {
		const c = allComplaints.find((curr) => curr.value === t);
		return {...c, highlighted: c.value === mainComplaint};
	});

	const token: string = getSessionId();
	const complaints = selectedComplaints.map((t) => ({complaint: t.id, highlighted: t.highlighted}));
	yield apiCallSaga(treatmentStatusSdk.addComplaintsToTreatment, token, treatment.id, complaints);
	yield put(treatmentStatusActions.setTreatmentComplaintsOfTreatment(patientId, selectedComplaints));
}

export function* reconcileTreatmentPersonnels(
	clientId: number,
	treatmentId: number,
	currentPersonnels: TreatmentPersonnel[],
	newPersonnels: TreatmentPersonnelFromForm[],
) {
	const token: string = getSessionId();
	const authUser: AuthUser = yield select(getAuthUser);
	const contactMap: ContactsMap = yield select((state: AppState) => state.contacts.treatmentTherapistsContactsMap);

	const allPersonnels = [];
	const getHashId = (id: number) => {
		if (id === authUser.id) return authUser.hashId;
		return contactMap[id].hashId;
	};

	// Remove personnels if needed.
	const personnelsToBeRemoved = currentPersonnels.filter((currentPersonnel) =>
		newPersonnels.every((personnel) => personnel.therapistId !== currentPersonnel.id),
	);

	for (let i = 0; i < personnelsToBeRemoved.length; i++) {
		yield apiCallSaga(
			treatmentStatusSdk.removeAPersonnelOfTreatment,
			token,
			treatmentId,
			personnelsToBeRemoved[i].publicId,
		);
	}

	// Update personnels if needed.
	const personnelsToBeUpdated = newPersonnels.filter((nextPersonnel) =>
		currentPersonnels.some((prevPersonnel) => nextPersonnel.therapistId === prevPersonnel.id),
	);

	for (let i = 0; i < personnelsToBeUpdated.length; i++) {
		const newPersonnel = yield apiCallSaga(
			treatmentStatusSdk.updateTreatmentPersonnelRole,
			token,
			treatmentId,
			getHashId(personnelsToBeUpdated[i].therapistId),
			personnelsToBeUpdated[i].role,
		);
		allPersonnels.push(newPersonnel);
	}

	// Add personnels if needed.
	const personnelsToBeAdded = newPersonnels.filter((nextPersonnel) =>
		currentPersonnels.every((prevPersonnel) => nextPersonnel.therapistId !== prevPersonnel.id),
	);

	for (let i = 0; i < personnelsToBeAdded.length; i++) {
		const newPersonnel = yield apiCallSaga(
			treatmentStatusSdk.addTreatmentPersonnelToTreatment,
			token,
			treatmentId,
			getHashId(personnelsToBeAdded[i].therapistId),
			personnelsToBeAdded[i].role,
		);
		allPersonnels.push(newPersonnel);
	}

	yield put(treatmentStatusActions.setTreatmentPersonnelsOfTreatment(clientId, allPersonnels));
}

export function* attachTreatmentPersonnels(
	treatment: SdkTreatment,
	patientId: number,
	personnels: TreatmentPersonnelFromForm[],
) {
	const currentTreatment: Treatment = yield select(getEditedTreatment);
	const currentPersonnels = currentTreatment?.personnels || [];

	yield call(reconcileTreatmentPersonnels, patientId, treatment.id, currentPersonnels, personnels);
}

export function* setTreatmentDetail(
	clientId: number,
	sdkTreatment: SdkTreatment,
	treatment: TreatmentFromForm,
	currentTreatment: Treatment,
) {
	yield put(
		treatmentStatusActions.setTreatmentStatus(clientId, {
			...sdkTreatment,
			patient: clientId,
			treatmentTypes: currentTreatment?.treatmentTypes || [],
			clientComplaints: currentTreatment?.clientComplaints || [],
			personnels: currentTreatment?.personnels || [],
		}),
	);

	// After storing the treatment information to backend,
	// it's time to store the treatment type and complaint of the treatment.
	// There's a backend limitation such that portal need to manage
	// the treatmentType and treatmentComplaint object.
	// For example, sometimes, portal need to create the treatmentType entry itself
	// in the backend, before "attaching" it to the treatment.
	//
	// Note also that we are using call here instead of fork.
	yield call(attachTreatmentType, sdkTreatment, clientId, treatment.treatmentTypes);
	yield call(attachTreatmentComplaint, sdkTreatment, clientId, treatment.clientComplaints, treatment.mainComplaint);

	// If the treatment is already ends, don't changes the personnels information.
	if (!sdkTreatment.endTime) {
		yield call(attachTreatmentPersonnels, sdkTreatment, clientId, treatment.personnels);
	}
}
/**
 * The saga that will be triggered when we create or edit a treatment
 * with TreatmentForm component.
 */
function* createOrUpdateTreatment(action: ActionType<typeof treatmentStatusActions.createOrUpdateTreatment.request>) {
	const token: string = getSessionId();
	const {clientId, treatment} = action.payload;
	yield put(treatmentStatusActions.updateTreatmentMutationLoadingState(LoadingState.LOADING));
	const currentTreatment: Treatment = yield select(getClientStatus(clientId));
	try {
		let sdkTreatment: SdkTreatment;
		if (treatment.id) {
			// The existence of id here means we have to update existing treatment
			sdkTreatment = yield apiCallSaga(treatmentStatusSdk.updateTreatment, token, {
				id: treatment.id,
				patient: clientId,
				startTime: treatment.startTime,
				disconnectedAt: treatment.disconnectedAt,
				endTime: treatment.endTime,
				terminatingReason: treatment.terminatingReason?.id,
				clientData: currentTreatment?.clientData || undefined,
				referralId: treatment.referralId,
				org_client_number: treatment.referralId,
			} as any);
		} else {
			// The non-existence of id here means we have to create a new treatment
			sdkTreatment = yield apiCallSaga(treatmentStatusSdk.createTreatment, token, {
				patient: clientId,
				startTime: treatment.startTime,
			});
		}
		yield call(setTreatmentDetail, clientId, sdkTreatment, treatment, currentTreatment);

		// Once we reach this point,
		// it means that we have successfully stored the treatment to backend.
		yield put(treatmentStatusActions.updateTreatmentMutationLoadingState(LoadingState.LOADED));
		yield put(treatmentStatusActions.closeTreatmentForm());
	} catch (error) {
		log.captureException(error);
		yield put(toastActions.addToast({message: "Failed to save the treatment", type: "error"}));

		// As the process of storing the treatment is long,
		// the best course of action right now in case of an error,
		// is to load the data again and show the latest stored state to the user.
		// That way, user can make necessary edit to correct the data.
		// TODO: change it to load only one treatment data, instead of all data,
		// once backend provide the API.
		yield put(treatmentStatusActions.loadTreatmentsOfClient.request({clientId}));
		yield put(treatmentStatusActions.updateTreatmentMutationLoadingState(LoadingState.ERROR));
	}
}

function* updateClientNumber(action: ActionType<typeof treatmentStatusActions.updateClientNumber.request>) {
	const {clientId, clientNumber} = action.payload;

	const treatment = yield select(getClientStatus(clientId));
	const clientData = treatment?.clientData;

	const user = yield select(getAuthUser);
	const org: Organization = yield select(getOrganizationById(user?.organization?.id));
	const orgKeyForm = (() => {
		if (org?.formKey) return org.formKey;
		if (org?.name.startsWith("PsyQ")) return OrgFormKey.PsyQ;
		if (org?.name.startsWith("Sol-Psychotherapie")) return OrgFormKey.Sol;
		if (org?.name.startsWith("Synaeda")) return OrgFormKey.Synaeda;
		if (org?.name.startsWith("Niceday")) return OrgFormKey.NDCenter;
	})();
	yield put(
		treatmentStatusActions.updateClientDetailsInTreatment.request({
			clientId,
			clientDetails: {...clientData?.value, clientNumber},
			orgKeyForm,
			hideToast: true,
		}),
	);
}

/**
 * This saga will update the client details in the treatment object.
 * The shape of the client details depends on the organization that's responsible for the treatment.
 */
function* updateClientDetailsInTreatment(
	action: ActionType<typeof treatmentStatusActions.updateClientDetailsInTreatment.request>,
) {
	const {clientId, clientDetails, orgKeyForm, hideToast} = action.payload;

	const token: string = getSessionId();
	let treatment: Treatment = yield select(getClientStatus(clientId));
	yield put(treatmentStatusActions.updateTreatmentMutationLoadingState(LoadingState.LOADING));

	if (!treatment) {
		treatment = yield call(automaticTreatmentCreationSaga, clientId);
	}

	try {
		const updatedTreatment = yield apiCallSaga(treatmentStatusSdk.updateTreatment, token, {
			...treatment,
			patient: clientId,
			terminatingReason: treatment.terminatingReason?.id,
			clientData: {
				value: clientDetails,
				orgKeyForm,

				// This version is hardcoded for now.
				// Currently, there's no plan in the future for portal
				// to ever utilize this version number.
				version: 1,
			},
		});

		yield put(
			treatmentStatusActions.setTreatmentStatus(clientId, {
				...updatedTreatment,
				patient: clientId,
				treatmentTypes: treatment?.treatmentTypes || [],
				clientComplaints: treatment?.clientComplaints || [],
				personnels: treatment?.personnels || [],
			}),
		);

		yield put(treatmentStatusActions.closeClientDetailsForm());
		yield put(treatmentStatusActions.updateTreatmentMutationLoadingState(LoadingState.LOADED));

		if (!hideToast) {
			yield put(
				toastActions.addToast({message: strTranslation.CLIENT_DETAILS.form.toast.success, type: "success"}),
			);
		}
	} catch (error) {
		log.captureException(error);
		yield put(treatmentStatusActions.updateTreatmentMutationLoadingState(LoadingState.ERROR));
		yield put(toastActions.addToast({message: strTranslation.CLIENT_DETAILS.form.toast.failed, type: "error"}));
	}
}

/**
 * Just like its name, this saga is where the logic to create or update treatment.
 */
export default function* () {
	yield takeEvery(getType(treatmentStatusActions.createOrUpdateTreatment.request), createOrUpdateTreatment);
	yield takeEvery(
		getType(treatmentStatusActions.updateClientDetailsInTreatment.request),
		updateClientDetailsInTreatment,
	);
	yield takeEvery(getType(treatmentStatusActions.updateClientNumber.request), updateClientNumber);
}
