import {authEvents} from "../auth/authAnalytics";
import {chatEvents} from "../chat/chatAnalytics";
import {customTrackerEvents} from "../tracker/customTracker/customTrackerAnalytics";
import {organizationEvents} from "../organizations/redux/organizationAnalytics";
import {ndBlogSearchEvents} from "../nicedayBlogSearch/redux/NDBlogSearchAnalytics";
import {psychoEducationEvents} from "../tracker/psychoEducation/redux/psychoEducationAnalytics";

import {callEvents} from "../call/callAnalytics";
import {timeTrackingEvents} from "../timeTracking/timeTrackingAnalytics";
import {fileSharingEvents} from "../fileSharing/redux/fileSharingAnalytics";
import {twoFAEvents} from "../twoFactorAuthentication/twoFAAnalytics";
import {calendarEvents} from "../calendar/redux/calendarAnalytics";
import {meetingNotesEvents} from "../tracker/meetingNotes/redux/meetingNotesAnalytics";
import {planActivityEvents} from "../tracker/events/redux/planActivityAnalytic";
import {behaviorExprEvents} from "../tracker/behaviorExpr/redux/behaviorExprAnalytics";

export const eventsMap = {
	...authEvents,
	...callEvents,
	...chatEvents,
	...customTrackerEvents,
	...organizationEvents,
	...ndBlogSearchEvents,
	...psychoEducationEvents,
	...meetingNotesEvents,
	...planActivityEvents,
	...timeTrackingEvents,
	...fileSharingEvents,
	...twoFAEvents,
	...calendarEvents,
	...behaviorExprEvents,
};
