import {Sensors} from "redux/tracking/TrackingTypes";
import strTranslation from "../../../../assets/lang/strings";
import {IGraphConfig} from "../../customTrackerGraphV2Types";
import {TrackerSensorName} from "../../customTrackerSensorTypes";
import {averageDurationConfig, IAverageDurationGraphConfig} from "./averageDurationConfig";
import {
	averageDurationPhysicalSensationConfig,
	averageIntensityPhysicalSensationConfig,
} from "./physicalSensationConfig";
import {averageIntensityConfig} from "./averageIntensityConfig";
import {averageQuantityConfig} from "./averageQuantityConfig";
import {booleanConfig} from "./booleanConfig";
import {checkInConfig} from "./checkInConfig";
import {quantityConfig} from "./quantityConfig";
import {researchEmotionsConfig} from "./researchEmotionsConfig";
import {totalRegistrationConfig} from "./totalRegistrationConfig";
import {NDColors} from "../../../../material-ui/NDColors";
import localization from "../../../../localization/Localization";

export const supportedSensorGraphV2 = {
	check_in: Sensors.CHECK_IN_FEELING,
	step_count: Sensors.STEP_COUNT,
};

export enum GRAPH_TIME_VIEW_VALUE {
	ALL_TIME = "AllTime",
	MONTH = "Month",
	WEEK = "Week",
}

// TODO: put translation here
export const GRAPH_TIME_VIEW_OPTIONS = [
	{
		value: GRAPH_TIME_VIEW_VALUE.ALL_TIME,
		translation: localization.formatMessage(strTranslation.GRAPH.GRAPHV2.time_options.all_time),
	},
	{
		value: GRAPH_TIME_VIEW_VALUE.MONTH,
		translation: localization.formatMessage(strTranslation.GRAPH.GRAPHV2.time_options.month),
	},
	{
		value: GRAPH_TIME_VIEW_VALUE.WEEK,
		translation: localization.formatMessage(strTranslation.GRAPH.GRAPHV2.time_options.week),
	},
];

type TotalIntensityDuration = [IGraphConfig, IGraphConfig, IGraphConfig];
type BooleanDuration = [IGraphConfig, IGraphConfig];

const aggressionAverageDurationConfig: IAverageDurationGraphConfig[] = [
	{
		id: strTranslation.CUSTOM_TRACKER.chart.physical_aggression.average_duration,
		color: NDColors.ORANGE,
	},
	{
		id: strTranslation.CUSTOM_TRACKER.chart.verbal_aggression.average_duration,
		color: NDColors.ROYAL_BLUE,
	},
];

const aggressionAverageIntensityConfig: IAverageDurationGraphConfig[] = [
	{
		id: strTranslation.CUSTOM_TRACKER.chart.physical_aggression.average_intensity,
		color: NDColors.ORANGE,
	},
	{
		id: strTranslation.CUSTOM_TRACKER.chart.verbal_aggression.average_intensity,
		color: NDColors.ROYAL_BLUE,
	},
];

export interface IGraphV2TrackerConfigs {
	// tracker with total, intensity , and duration
	[TrackerSensorName.automaticPilot]: TotalIntensityDuration;
	[TrackerSensorName.backache]: TotalIntensityDuration;
	[TrackerSensorName.compulsion]: TotalIntensityDuration;
	[TrackerSensorName.cramped]: TotalIntensityDuration;
	[TrackerSensorName.craving]: TotalIntensityDuration;
	[TrackerSensorName.depersonalization]: TotalIntensityDuration;
	[TrackerSensorName.depressed]: TotalIntensityDuration;
	[TrackerSensorName.disoriented]: TotalIntensityDuration;
	[TrackerSensorName.distracted]: TotalIntensityDuration;
	[TrackerSensorName.gratitude]: TotalIntensityDuration;
	[TrackerSensorName.headache]: TotalIntensityDuration;
	[TrackerSensorName.hyperAlert]: TotalIntensityDuration;
	[TrackerSensorName.hyperAroused]: TotalIntensityDuration;
	[TrackerSensorName.inDanger]: TotalIntensityDuration;
	[TrackerSensorName.loneliness]: TotalIntensityDuration;
	[TrackerSensorName.panicAttack]: TotalIntensityDuration;
	[TrackerSensorName.pain]: TotalIntensityDuration;
	[TrackerSensorName.physicalWeakness]: TotalIntensityDuration;
	[TrackerSensorName.physicallyFrozen]: TotalIntensityDuration;
	[TrackerSensorName.withdrawal]: TotalIntensityDuration;

	// tracker with average intensity
	[TrackerSensorName.afraid]: [IGraphConfig];
	[TrackerSensorName.angry]: [IGraphConfig];
	[TrackerSensorName.ashamed]: [IGraphConfig];
	[TrackerSensorName.curious]: [IGraphConfig];
	[TrackerSensorName.difficulty]: [IGraphConfig];
	[TrackerSensorName.energy]: [IGraphConfig];
	[TrackerSensorName.exercise]: [IGraphConfig];
	[TrackerSensorName.fatigue]: [IGraphConfig];
	[TrackerSensorName.guilty]: [IGraphConfig];
	[TrackerSensorName.happy]: [IGraphConfig];
	[TrackerSensorName.hyperAssertiveness]: [IGraphConfig];
	[TrackerSensorName.insecure]: [IGraphConfig];
	[TrackerSensorName.sad]: [IGraphConfig];
	[TrackerSensorName.sideEffectsMedication]: [IGraphConfig];
	[TrackerSensorName.socialAvoidance]: [IGraphConfig];
	[TrackerSensorName.socialDependent]: [IGraphConfig];
	[TrackerSensorName.subAssertiveness]: [IGraphConfig];
	[TrackerSensorName.tension]: [IGraphConfig];

	// tracker with average duration
	[TrackerSensorName.bodycheck]: [IGraphConfig];
	[TrackerSensorName.exercise]: [IGraphConfig];
	[TrackerSensorName.mindfulness]: [IGraphConfig];
	[TrackerSensorName.restAndRelax]: [IGraphConfig];
	[TrackerSensorName.worry]: [IGraphConfig];

	// tracker with boolean and average duration
	[TrackerSensorName.avoidance]: BooleanDuration;
	[TrackerSensorName.flashbacks]: BooleanDuration;
	[TrackerSensorName.memoryLoss]: BooleanDuration;
	[TrackerSensorName.nightmares]: BooleanDuration;
	[TrackerSensorName.safetyBehaviour]: BooleanDuration;

	// tracker with total quantity
	[TrackerSensorName.alcohol]: [IGraphConfig];
	[TrackerSensorName.positiveThings]: [IGraphConfig];
	[TrackerSensorName.smoking]: [IGraphConfig];

	// tracker with boolean
	[TrackerSensorName.askForHelp]: [IGraphConfig];
	[TrackerSensorName.assertiveness]: [IGraphConfig];

	// tracker with total, physical, verbal aggression
	[TrackerSensorName.aggression]: [IGraphConfig, IGraphConfig, IGraphConfig];

	// tracker with total quantity and average duration
	[TrackerSensorName.binge]: [IGraphConfig, IGraphConfig];

	// tracker with total, and panic attack with physical sensation
	[TrackerSensorName.panicAttackWithPhysicalSensation]: [IGraphConfig, IGraphConfig, IGraphConfig];

	// multi intensity 3d research emotions
	[TrackerSensorName.researchEmotions]: [IGraphConfig];

	// average quantity
	[TrackerSensorName.weight]: [IGraphConfig];

	// check-in Feeling
	[Sensors.CHECK_IN_FEELING]: [IGraphConfig];
}

export const graphV2TrackerConfigs: IGraphV2TrackerConfigs = {
	// tracker with total, intensity, and duration
	[TrackerSensorName.automaticPilot]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureAutomaticPilot"]),
		averageDurationConfig(["measureAutomaticPilot"]),
	],
	[TrackerSensorName.backache]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureBackache"]),
		averageDurationConfig(["measureBackache"]),
	],
	[TrackerSensorName.compulsion]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureCompulsion"]),
		averageDurationConfig(["measureCompulsion"]),
	],
	[TrackerSensorName.cramped]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureCramped"]),
		averageDurationConfig(["measureCramped"]),
	],
	[TrackerSensorName.craving]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureCraving"]),
		averageDurationConfig(["measureCraving"]),
	],
	[TrackerSensorName.depersonalization]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureDepersonalization"]),
		averageDurationConfig(["measureDepersonalization"]),
	],
	[TrackerSensorName.depressed]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureDepressed"]),
		averageDurationConfig(["measureDepressed"]),
	],
	[TrackerSensorName.disoriented]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureDisoriented"]),
		averageDurationConfig(["measureDisoriented"]),
	],
	[TrackerSensorName.distracted]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureDistracted"]),
		averageDurationConfig(["measureDistracted"]),
	],
	[TrackerSensorName.gratitude]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureGratitude"]),
		averageDurationConfig(["measureGratitude"]),
	],
	[TrackerSensorName.headache]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureHeadache"]),
		averageDurationConfig(["measureHeadache"]),
	],
	[TrackerSensorName.hyperAlert]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureHyperAlert"]),
		averageDurationConfig(["measureHyperAlert"]),
	],
	[TrackerSensorName.hyperAroused]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureHyperAroused"]),
		averageDurationConfig(["measureHyperAroused"]),
	],
	[TrackerSensorName.inDanger]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureInDanger"]),
		averageDurationConfig(["measureInDanger"]),
	],
	[TrackerSensorName.loneliness]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureLoneliness"]),
		averageDurationConfig(["measureLoneliness"]),
	],
	[TrackerSensorName.panicAttack]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measurePanicAttacks"]),
		averageDurationConfig(["measurePanicAttacks"]),
	],
	[TrackerSensorName.pain]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measurePain"]),
		averageDurationConfig(["measurePain"]),
	],
	[TrackerSensorName.physicallyFrozen]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measurePhysicallyFrozen"]),
		averageDurationConfig(["measurePhysicallyFrozen"]),
	],
	[TrackerSensorName.physicalWeakness]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measurePhysicalWeakness"]),
		averageDurationConfig(["measurePhysicalWeakness"]),
	],
	[TrackerSensorName.withdrawal]: [
		totalRegistrationConfig(),
		averageIntensityConfig(["measureWithdrawalSymptoms"]),
		averageDurationConfig(["measureWithdrawalSymptoms"]),
	],

	// tracker with average intensity
	[TrackerSensorName.afraid]: [averageIntensityConfig(["measureAfraid"])],
	[TrackerSensorName.angry]: [averageIntensityConfig(["measureAngry"])],
	[TrackerSensorName.ashamed]: [averageIntensityConfig(["measureAshamed"])],
	[TrackerSensorName.curious]: [averageIntensityConfig(["measureCuriosity"])],
	[TrackerSensorName.difficulty]: [averageIntensityConfig(["measureDifficulty"])],
	[TrackerSensorName.energy]: [averageIntensityConfig(["measureEnergy"])],
	[TrackerSensorName.exercise]: [averageIntensityConfig(["measureExercise"])],
	[TrackerSensorName.fatigue]: [averageIntensityConfig(["measureFatigue"])],
	[TrackerSensorName.guilty]: [averageIntensityConfig(["measureGuilty"])],
	[TrackerSensorName.happy]: [averageIntensityConfig(["measureHappy"])],
	[TrackerSensorName.hyperAssertiveness]: [averageIntensityConfig(["measureHyperAssertiveness"])],
	[TrackerSensorName.insecure]: [averageIntensityConfig(["measureInsecure"])],
	[TrackerSensorName.sad]: [averageIntensityConfig(["measureSad"])],
	[TrackerSensorName.sideEffectsMedication]: [averageIntensityConfig(["measureSideEffectsMedication"])],
	[TrackerSensorName.socialAvoidance]: [averageIntensityConfig(["measureSocialAvoidance"])],
	[TrackerSensorName.socialDependent]: [averageIntensityConfig(["measureSocialDependent"])],
	[TrackerSensorName.subAssertiveness]: [averageIntensityConfig(["measureSubAssertiveness"])],
	[TrackerSensorName.tension]: [averageIntensityConfig(["measureTension"])],

	// tracker with average duration
	[TrackerSensorName.bodycheck]: [averageDurationConfig(["measureBodycheck"])],
	[TrackerSensorName.exercise]: [averageDurationConfig(["measureExercise"])],
	[TrackerSensorName.mindfulness]: [averageDurationConfig(["measureMindfulness"])],
	[TrackerSensorName.restAndRelax]: [averageDurationConfig(["measureRelaxation"])],
	[TrackerSensorName.worry]: [averageDurationConfig(["measureWorry"])],

	// tracker with boolean and average duration
	[TrackerSensorName.avoidance]: [averageDurationConfig(["measureAvoidance"]), booleanConfig("measureAvoidance")],
	[TrackerSensorName.flashbacks]: [averageDurationConfig(["measureFlashbacks"]), booleanConfig("measureFlashbacks")],
	[TrackerSensorName.memoryLoss]: [averageDurationConfig(["measureMemoryLoss"]), booleanConfig("measureMemoryLoss")],
	[TrackerSensorName.nightmares]: [averageDurationConfig(["measureNightmares"]), booleanConfig("measureNightmares")],
	[TrackerSensorName.safetyBehaviour]: [
		averageDurationConfig(["measureSafetyBehaviour"]),
		booleanConfig("measureSafetyBehaviour"),
	],

	// tracker with total quantity
	[TrackerSensorName.alcohol]: [quantityConfig("measureUnitOfAlcohol")],
	[TrackerSensorName.positiveThings]: [quantityConfig("measurePositiveThings")],
	[TrackerSensorName.smoking]: [quantityConfig("measureCigarettes")],

	// tracker with boolean
	[TrackerSensorName.askForHelp]: [booleanConfig("measureAskForHelp")],
	[TrackerSensorName.assertiveness]: [booleanConfig("measureAssertiveness")],

	// tracker with total, physical, and verbal aggression
	[TrackerSensorName.aggression]: [
		totalRegistrationConfig(),
		averageDurationConfig(
			["measurePhysicalAggression", "measureVerbalAggression"],
			aggressionAverageDurationConfig,
		),
		averageIntensityConfig(
			["measurePhysicalAggression", "measureVerbalAggression"],
			aggressionAverageIntensityConfig,
		),
	],

	// tracker with total quantity and average duration
	[TrackerSensorName.binge]: [quantityConfig("measureBinge"), averageDurationConfig(["measureBinge"])],

	// tracker with total, and panic attack with physical sensation
	[TrackerSensorName.panicAttackWithPhysicalSensation]: [
		totalRegistrationConfig(),
		averageIntensityPhysicalSensationConfig(["measurePanicAttacks"]),
		averageDurationPhysicalSensationConfig(["measurePanicAttacks"]),
	],

	// multi intensity 3d research emotions
	[TrackerSensorName.researchEmotions]: [researchEmotionsConfig()],

	// average quantity
	[TrackerSensorName.weight]: [
		averageQuantityConfig("measureWeight", strTranslation.CUSTOM_TRACKER.chart.weight.kilograms),
	],

	// check-in Feeling
	[Sensors.CHECK_IN_FEELING]: [checkInConfig()],
};
