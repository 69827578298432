import {Typography} from "@material-ui/core";
import {useMemo} from "react";
import strTranslation from "../../../../assets/lang/strings";
import localization from "../../../../localization/Localization";
import {NDColors} from "../../../../material-ui/NDColors";
import {
	GraphV2Type,
	IGraphConfig,
	IGraphV2Data,
	IUseGraphData,
	TrackerGroupByDate,
} from "../../customTrackerGraphV2Types";
import {GRAPH_TIME_VIEW_VALUE} from "./graphV2Config";
import {getActiveStringDates, getEndDate, transformRegistrationDateFromString} from "./graphV2ConfigHelper";
import {useGetXTickValues, useGetYTickValues} from "./graphV2TickHooks";

// minimum value of Y scale
const HIGHEST_Y_SCALE: number = 60;
// always return 6 lines start from 0
const Y_LINES: number = 6;

export const averageQuantityConfig = (measureString: string, yLegendTranslationString: string): IGraphConfig => {
	return {
		graphType: GraphV2Type.AVERAGE_QUANTITY,
		graphName: localization.formatMessage(strTranslation.CUSTOM_TRACKER.chart.quantity),
		yLegend: localization.formatMessage(yLegendTranslationString),
		xLegend: localization.formatMessage(strTranslation.TIME.date),
		yToolTipFormatter: (label, value) => {
			const text = localization.formatMessage(label);
			return (
				<Typography variant="body2">{`${localization.formatMessage(
					strTranslation.CUSTOM_TRACKER.chart.average_prefix,
					{text},
				)} : ${value}`}</Typography>
			);
		},
		useGraphData: (
			trackerData: TrackerGroupByDate,
			trackerName: string,
			graphType: GraphV2Type,
			startDate: Date,
			selectedTimeView: GRAPH_TIME_VIEW_VALUE,
		): IUseGraphData => {
			const endDate = getEndDate(selectedTimeView, startDate);

			// data
			const graphData: IGraphV2Data[] = useMemo(() => {
				const activeDates: string[] = getActiveStringDates(trackerData, selectedTimeView, startDate, endDate);
				const data = [
					{
						id: yLegendTranslationString,
						color: NDColors.ORANGE,
						data: activeDates
							.map((date) => {
								const xDate = transformRegistrationDateFromString(date);
								const totalQuantity = trackerData[date].reduce((total, tracker) => {
									const quantity =
										tracker.value.measures[measureString]?.sensorData?.value?.quantity ?? 0;
									return total + quantity;
								}, 0);

								const lengthData = trackerData[date].length;
								const averageQuantity = totalQuantity / lengthData;
								return {
									x: xDate,
									y: Number(averageQuantity.toPrecision(2)),
								};
							})
							.filter((data) => data.y > 0),
					},
				];

				// filter graph that contains zero value
				// https://github.com/plouc/nivo/issues/1854
				return data.filter((res) => res.data.length > 0);

				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [trackerData, trackerName, graphType, selectedTimeView, startDate]);

			const yTickValues: number[] = useGetYTickValues(graphData, HIGHEST_Y_SCALE, Y_LINES);

			const xTickValues: Date[] = useGetXTickValues(graphData, selectedTimeView, startDate, endDate);

			return {
				data: graphData,
				yMax: yTickValues[yTickValues.length - 1],
				yTickValues,
				xMin: xTickValues[0],
				xMax: xTickValues[xTickValues.length - 1],
				xTickValues,
			};
		},
	};
};
