import {Typography} from "@material-ui/core";
import {SensorDatum} from "redux/tracking/TrackingTypes";
import strTranslation from "../../../../assets/lang/strings";
import localization from "../../../../localization/Localization";
import {GraphV2Type, IGraphConfig} from "../../customTrackerGraphV2Types";
import {TrackerSensorName} from "../../customTrackerSensorTypes";
import {CustomTrackerSensor} from "../../customTrackerTypes";
import {getTranslatedCustomTracker} from "../trackerLocalizations";
import {averageDurationConfig} from "./averageDurationConfig";
import {averageIntensityConfig} from "./averageIntensityConfig";

const physicalSensationMeasure = [
	"measureDizzy",
	"measureFearOfDying",
	"measureFearOfLosingControl",
	"measureFeelingOfSuffocation",
	"measureFeelingUnreal",
	"measureNausea",
	"measurePainOnChest",
	"measurePalpitations",
	"measureShaking",
	"measureShortnessOfBreath",
	"measureSuddenlyWarmOrCold",
	"measureSweating",
	"measureTinglingInHandsOrFeet",
];

interface ITooltipFormatter {
	label: string;
	value: number;
	trackerData: SensorDatum<CustomTrackerSensor>[];
}

const ToolTipFormatter = ({label, value, trackerData}: ITooltipFormatter) => {
	const text = localization.formatMessage(label);

	if (!trackerData) {
		return <Typography variant="body2">{`${text} : ${value}`}</Typography>;
	}
	const physicalSensations = trackerData.reduce((tempMeasures, tracker) => {
		const existMeasures = physicalSensationMeasure.filter(
			(measure) => tracker?.value?.measures[measure]?.sensorData?.value?.boolean,
		);
		return tempMeasures.concat(existMeasures.filter((existMeasure) => tempMeasures.indexOf(existMeasure) < 0));
	}, []);

	const translatedPhysicalSensations = physicalSensations.map((measure) =>
		getTranslatedCustomTracker(TrackerSensorName[measure]),
	);

	const isMoreThanFive = translatedPhysicalSensations.length > 5;

	const slicedTranslatedPhysicalSensations = translatedPhysicalSensations.slice(0, 5);
	return (
		<>
			<Typography variant="body2">{`${text} : ${value}`}</Typography>
			<Typography variant="body2" style={{fontWeight: 500}}>
				{localization.formatMessage(strTranslation.ZZ_CUSTOM_TRACKER.property.physical_sensation.name)}
			</Typography>
			<Typography variant="body2">
				{slicedTranslatedPhysicalSensations.map(
					(physicalSensation, i) =>
						`${physicalSensation}${
							// put a comma if the item is not last
							(i !== slicedTranslatedPhysicalSensations.length - 1 || isMoreThanFive) && ", "
						}`,
				)}
				{
					// TODO: put translation here
					isMoreThanFive && "and more"
				}
			</Typography>
		</>
	);
};

export const averageDurationPhysicalSensationConfig = (measures: string[]): IGraphConfig => {
	return {
		graphType: GraphV2Type.AVERAGE_DURATION,
		graphName: averageDurationConfig(measures).graphName,
		yLegend: averageDurationConfig(measures).yLegend,
		xLegend: averageDurationConfig(measures).xLegend,
		yToolTipFormatter: (label, value, trackerData) => (
			<ToolTipFormatter label={label} value={value} trackerData={trackerData} />
		),
		useGraphData: averageDurationConfig(measures).useGraphData,
	};
};

export const averageIntensityPhysicalSensationConfig = (measures: string[]): IGraphConfig => {
	return {
		graphType: GraphV2Type.AVERAGE_INTENSITY,
		graphName: averageIntensityConfig(measures).graphName,
		yLegend: averageIntensityConfig(measures).yLegend,
		xLegend: averageIntensityConfig(measures).xLegend,
		yToolTipFormatter: (label, value, trackerData) => (
			<ToolTipFormatter label={label} value={value} trackerData={trackerData} />
		),
		useGraphData: averageIntensityConfig(measures).useGraphData,
	};
};
