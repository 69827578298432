import {LoadingState} from "constants/redux";
import {Action, PayloadAction, PayloadActionCreator, getType} from "typesafe-actions";

/**
 * A function that can be used inside a reducer function to deal with the
 * necessary boilerplate when dealing with LoadingState and AsyncAction.
 *
 * @param actions
 * @returns - Returned function will accept as arguments LoadingState and action,
 *     and returns a new LoadingState if needed.
 */
export function loadingStateReducer<X extends string, Y extends string, Z extends string, A, B, C>(actions: {
	request: PayloadActionCreator<X, A>;
	success: PayloadActionCreator<Y, B>;
	failure: PayloadActionCreator<Z, C>;
}) {
	return (
		state: LoadingState,
		action: PayloadAction<X, A> | PayloadAction<Y, B> | PayloadAction<Z, C> | Action,
	): LoadingState => {
		switch (action.type) {
			case getType(actions.request): {
				return LoadingState.LOADING;
			}

			case getType(actions.success): {
				return LoadingState.LOADED;
			}

			case getType(actions.failure): {
				return LoadingState.ERROR;
			}

			default:
				return state;
		}
	};
}

/**
 * A function that can be used inside a `produce`-ed reducer function, that implements
 * the boilerplate logic when dealing with LoadingState and AsyncFunction, where
 * the reducing function store the loading state in a Record<string,LoadingState> form.

 * @param actions 
 * @param actionToId - Function to map an action to a unique id for the Record<string,LoadingState>
 * @returns - a mutating function that can apply the loading state logic inside the reducing function.
 */
export function loadingStateMapReducer<X extends string, Y extends string, Z extends string, A, B, C>(
	actions: {
		request: PayloadActionCreator<X, A>;
		success: PayloadActionCreator<Y, B>;
		failure: PayloadActionCreator<Z, C>;
	},
	actionToId: (action: PayloadAction<X, A> | PayloadAction<Y, B> | PayloadAction<Z, C>) => string | number,
) {
	return (
		state: Record<number | string, LoadingState>,
		action: PayloadAction<X, A> | PayloadAction<Y, B> | PayloadAction<Z, C> | Action,
	) => {
		switch (action.type) {
			case getType(actions.request): {
				state[actionToId(action as PayloadAction<X, A>)] = LoadingState.LOADING;
				return;
			}

			case getType(actions.success): {
				state[actionToId(action as PayloadAction<Y, B>)] = LoadingState.LOADED;
				return;
			}

			case getType(actions.failure): {
				state[actionToId(action as PayloadAction<Z, C>)] = LoadingState.ERROR;
				return;
			}
		}
	};
}
