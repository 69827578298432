import {Typography} from "@material-ui/core";
import {CheckIn} from "@sense-os/sensor-schema/goalie-2-ts/check_in";
import {useMemo} from "react";
import {SensorDatum} from "redux/tracking/TrackingTypes";
import strTranslation from "../../../../assets/lang/strings";
import localization from "../../../../localization/Localization";
import {NDColors} from "../../../../material-ui/NDColors";
import {
	GraphV2Type,
	IGraphConfig,
	IGraphV2Data,
	IUseGraphData,
	TrackerGroupByDate,
} from "../../customTrackerGraphV2Types";
import {GRAPH_TIME_VIEW_VALUE} from "./graphV2Config";
import {getActiveStringDates, getEndDate, transformRegistrationDateFromString} from "./graphV2ConfigHelper";
import {useGetXTickValues, useGetYTickValues} from "./graphV2TickHooks";

// minimum value of Y scale
const HIGHEST_Y_SCALE: number = 10;
// always return 6 lines start from 0
const Y_LINES: number = 6;

const moodIcon = [
	"/assets/svg/mood_0-of-10.svg",
	"/assets/svg/mood_2-of-10.svg",
	"/assets/svg/mood_4-of-10.svg",
	"/assets/svg/mood_6-of-10.svg",
	"/assets/svg/mood_8-of-10.svg",
	"/assets/svg/mood_10-of-10.svg",
];

export const checkInConfig = (): IGraphConfig => {
	return {
		graphType: GraphV2Type.CHECK_IN_FEELING,
		graphName: localization.formatMessage(strTranslation.ZZ_CORE_TRACKER.tracker.check_in.name),
		yLegend: localization.formatMessage(strTranslation.CUSTOM_TRACKER.chart.average_intensity),
		xLegend: localization.formatMessage(strTranslation.TIME.date),
		yToolTipFormatter: (label, value) => {
			const labelTranslated = localization.formatMessage(label);
			const moodIndex = Math.floor(value / 2);
			return (
				<>
					<Typography variant="body2">{`${localization.formatMessage(
						strTranslation.CUSTOM_TRACKER.chart.average_prefix,
						{text: labelTranslated},
					)} : ${value}`}</Typography>
					<img src={moodIcon[moodIndex]} />
				</>
			);
		},
		useGraphData: (
			trackerData: TrackerGroupByDate,
			trackerName: string,
			graphType: GraphV2Type,
			startDate: Date,
			selectedTimeView: GRAPH_TIME_VIEW_VALUE,
		): IUseGraphData => {
			const endDate = getEndDate(selectedTimeView, startDate);

			// data
			const graphData: IGraphV2Data[] = useMemo(() => {
				const activeDates: string[] = getActiveStringDates(trackerData, selectedTimeView, startDate, endDate);

				const data = {
					id: strTranslation.ZZ_CUSTOM_TRACKER.tracker.category.mood,
					color: NDColors.ORANGE,
					data: activeDates
						.map((date) => {
							const xDate = transformRegistrationDateFromString(date);
							const totalIntensity = trackerData[date].reduce((total, tracker) => {
								// check-in isn't part of custom tracker
								const trackerCheckIn = tracker as unknown as SensorDatum<CheckIn>;
								const intensity = trackerCheckIn?.value?.mood;
								return total + intensity + 1;
							}, 0);
							const lengthData = trackerData[date].length;
							const averageIntensity = totalIntensity / lengthData;
							return {
								x: xDate,
								y: Number(averageIntensity.toPrecision(2)),
							};
						})
						.filter((data) => data.y > 0),
				};

				// filter graph that contains zero value
				// https://github.com/plouc/nivo/issues/1854
				return [data].filter((res) => res.data.length > 0);

				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [trackerData, trackerName, graphType, selectedTimeView, startDate]);

			const yTickValues: number[] = useGetYTickValues(graphData, HIGHEST_Y_SCALE, Y_LINES);

			const xTickValues: Date[] = useGetXTickValues(graphData, selectedTimeView, startDate, endDate);

			return {
				data: graphData,
				yMax: yTickValues[yTickValues.length - 1],
				yTickValues,
				xMin: xTickValues[0],
				xMax: xTickValues[xTickValues.length - 1],
				xTickValues,
			};
		},
	};
};
