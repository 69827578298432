import {getTranslatedCustomTracker} from "../trackerLocalizations";
import localization from "../../../../localization/Localization";
import {graphV2TrackerConfigs, supportedSensorGraphV2} from "./graphV2Config";
import strTranslation from "../../../../assets/lang/strings";
import {TrackerSensorName} from "../../customTrackerSensorTypes";

/**
 * Returns true if Sensor is supported by the web portal.
 * Unsupported preset trackers are trackers which the portal don't have the configuration yet.
 */
export function isSupportedSensorGraphV2(sensorName: string): boolean {
	return !!supportedSensorGraphV2[sensorName];
}

export const getTranslatedGraphName = (sensorName: string): string => {
	if (isSupportedSensorGraphV2(sensorName)) {
		return localization.formatMessage(strTranslation.ZZ_CORE_TRACKER.tracker[sensorName].name);
	}
	if (isGraphV2Supported(sensorName)) return getTranslatedCustomTracker(sensorName as unknown as TrackerSensorName);
	return "";
};

/**
 * Returns true if Tracker is supported by graph v2
 */
export const isGraphV2Supported = (sensorName: string): boolean => {
	return !!graphV2TrackerConfigs[sensorName];
};
