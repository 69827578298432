import {LoadingState} from "constants/redux";
import produce from "immer";
import {getType} from "typesafe-actions";
import {PsychoEducationState} from "../psychoEducationTypes";
import {PsychoEducationActionType, psychoEducationActions} from "./psychoEducationActions";

const initialState: PsychoEducationState = {
	isFormOpened: false,
	savingState: LoadingState.EMPTY,
	deletingState: LoadingState.EMPTY,
	articleSavingState: LoadingState.EMPTY,
	articleDeletingState: LoadingState.EMPTY,
	savedFormValues: {},
	psychoEducationFetchState: LoadingState.EMPTY,
	psychoEducationData: null,
	removedArticleIds: [],
	userId: null,
	id: null,
};

export const psychoEducationReducer = produce(
	(state: PsychoEducationState = initialState, action: PsychoEducationActionType) => {
		switch (action.type) {
			case getType(psychoEducationActions.openForm):
				state.isFormOpened = true;
				state.id = action.payload.id || null;
				state.userId = action.payload.userId;
				return;
			case getType(psychoEducationActions.closeForm):
				state.isFormOpened = false;
				state.id = null;
				state.savingState = LoadingState.EMPTY;
				return;
			case getType(psychoEducationActions.saveFormValues): {
				const formValues = action.payload.formValues;
				state.savedFormValues = {
					...state.savedFormValues,
					[action.payload.userId]: {
						articles: formValues.articles,
						title: formValues.title,
						description: formValues.description,
						dueDate: formValues.dueDate,
						reminderToggle: formValues.reminderToggle,
						reminderDuration: formValues.reminderDuration,
						reminderTimeUnit: formValues.reminderTimeUnit,
					},
				};
				return;
			}
			case getType(psychoEducationActions.savePsychoEducation.request):
				state.savingState = LoadingState.LOADING;
				return;
			case getType(psychoEducationActions.savePsychoEducation.success):
				state.savingState = LoadingState.LOADED;
				state.savedFormValues = {
					...state.savedFormValues,
					[action.payload.userId]: null,
				};
				return;
			case getType(psychoEducationActions.savePsychoEducation.failure):
				state.savingState = LoadingState.ERROR;
				return;
			case getType(psychoEducationActions.fetchPsychoEducation.request):
				state.psychoEducationFetchState = LoadingState.LOADING;
				return;
			case getType(psychoEducationActions.fetchPsychoEducation.success):
				const data = action.payload.psychoEducationData;
				state.psychoEducationFetchState = LoadingState.LOADED;
				state.psychoEducationData = data;
				return;
			case getType(psychoEducationActions.fetchPsychoEducation.failure):
				state.psychoEducationFetchState = LoadingState.ERROR;
				return;
			case getType(psychoEducationActions.deletePsychoEducation.request):
				state.deletingState = LoadingState.LOADING;
				return;
			case getType(psychoEducationActions.deletePsychoEducation.success):
				state.deletingState = LoadingState.LOADED;
				state.psychoEducationData = null;
				state.id = null;
				return;
			case getType(psychoEducationActions.deletePsychoEducation.failure):
				state.deletingState = LoadingState.ERROR;
				return;
			case getType(psychoEducationActions.saveArticles.request):
				state.articleSavingState = LoadingState.LOADING;
				return;
			case getType(psychoEducationActions.saveArticles.success):
				state.articleSavingState = LoadingState.LOADED;
				return;
			case getType(psychoEducationActions.saveArticles.failure):
				state.articleSavingState = LoadingState.ERROR;
				return;
			case getType(psychoEducationActions.updateArticles.request):
				state.articleSavingState = LoadingState.LOADING;
				return;
			case getType(psychoEducationActions.updateArticles.success):
				state.articleSavingState = LoadingState.LOADED;
				return;
			case getType(psychoEducationActions.updateArticles.failure):
				state.articleSavingState = LoadingState.ERROR;
				return;
			case getType(psychoEducationActions.removeArticles.request):
				state.articleDeletingState = LoadingState.LOADING;
				return;
			case getType(psychoEducationActions.removeArticles.success):
				state.articleDeletingState = LoadingState.LOADED;
				return;
			case getType(psychoEducationActions.removeArticles.failure):
				state.articleDeletingState = LoadingState.ERROR;
				return;
			case getType(psychoEducationActions.setRemovedArticleId):
				state.removedArticleIds = [...state.removedArticleIds, ...action.payload.articleIds];
				return;
		}
	},
	initialState,
);
