import {useMemo} from "react";
import strTranslation from "../../../../assets/lang/strings";
import localization from "../../../../localization/Localization";
import {NDColors} from "../../../../material-ui/NDColors";
import {
	GraphV2Type,
	IGraphConfig,
	IGraphV2Data,
	IUseGraphData,
	TrackerGroupByDate,
} from "../../customTrackerGraphV2Types";
import {GRAPH_TIME_VIEW_VALUE} from "./graphV2Config";
import {getActiveStringDates, getEndDate, transformRegistrationDateFromString} from "./graphV2ConfigHelper";
import {useGetXTickValues, useGetYTickValues} from "./graphV2TickHooks";

// minimum value of Y scale
const HIGHEST_Y_SCALE: number = 10;
// always return 6 lines start from 0
const Y_LINES: number = 6;

export const totalRegistrationConfig = (): IGraphConfig => {
	return {
		graphType: GraphV2Type.TOTAL_REGISTRATION,
		graphName: localization.formatMessage(strTranslation.GRAPHS.events.header),
		yLegend: localization.formatMessage(strTranslation.GRAPHS.events.header),
		xLegend: localization.formatMessage(strTranslation.TIME.date),
		useGraphData: (
			trackerData: TrackerGroupByDate,
			trackerName: string,
			graphType: GraphV2Type,
			startDate: Date,
			selectedTimeView: GRAPH_TIME_VIEW_VALUE,
		): IUseGraphData => {
			const endDate = getEndDate(selectedTimeView, startDate);

			// data
			const graphData: IGraphV2Data[] = useMemo(() => {
				const activeDates: string[] = getActiveStringDates(trackerData, selectedTimeView, startDate, endDate);
				const data = [
					{
						id: strTranslation.GRAPHS.events.header,
						color: NDColors.ORANGE,
						data: activeDates.map((date) => {
							const xDate = transformRegistrationDateFromString(date);
							return {
								x: xDate,
								y: trackerData[date].length,
							};
						}),
					},
				];

				// filter graph that contains zero value
				// https://github.com/plouc/nivo/issues/1854
				return data.filter((res) => res.data.length > 0);

				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [trackerData, trackerName, graphType, selectedTimeView, startDate]);

			const yTickValues: number[] = useGetYTickValues(graphData, HIGHEST_Y_SCALE, Y_LINES);
			const xTickValues: Date[] = useGetXTickValues(graphData, selectedTimeView, startDate, endDate);

			return {
				data: graphData,
				yMax: yTickValues[yTickValues.length - 1],
				yTickValues,
				xMin: xTickValues[0],
				xMax: xTickValues[xTickValues.length - 1],
				xTickValues,
			};
		},
	};
};
