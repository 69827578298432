import moment from "moment";
import {call, put, select} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import {
	BehaviorExperimentDetailResponse,
	BehaviorExperimentPostRequestBody,
	BehaviorExperimentPutRequestBody,
} from "@sense-os/goalie-js/dist/behaviorExperiment";

import createLogger from "../../../logger/createLogger";
import {behaviorExprActions} from "../redux/behaviorExprActions";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {toastActions} from "../../../toaster/redux";
import strTranslation from "../../../assets/lang/strings";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import behaviorExprSDK from "../helpers/behaviorExprSDK";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {getBehaviorExprDetailResponse} from "../redux/behaviorExprSelector";
import {getBehaviorReminderDateTime} from "../helpers/behaviorExprHelpers";
import localization from "../../../localization/Localization";
import {onAutoEnableCoreTracker} from "../../customTracker/helpers/onAutoEnableCoreTracker";
import {CoreTrackerId} from "../../utils/coreTracker";

const log = createLogger("saveBehaviorExperimentSaga", SentryTags.BehaviorExperiment);

/**
 * Handle Save Behavior Experiment
 */
export function* saveBehaviorExperiment(action: ActionType<typeof behaviorExprActions.saveBehaviorExpr.request>) {
	try {
		const {userId, formValues, isEditing} = action.payload;

		log.debug("saving behavior experiment data", {userId, formValues});
		const token: string = yield call(getSessionId);

		// Set `remindedDateTime` to `null` if `reminderToggle` is not enabled
		const reminderDateTime: Date = yield call(getBehaviorReminderDateTime, formValues.reminderToggle && formValues);

		// Show warning toast when `reminderDateTime` is before creation date
		if (moment(reminderDateTime).isBefore()) {
			yield put(
				toastActions.addToast({
					message: localization.formatMessage(strTranslation.VALIDATION.reminder_must_be_in_future),
					type: "warning",
				}),
			);
			yield put(behaviorExprActions.saveBehaviorExpr.failure(new Error()));
			return;
		}
		// Create new behavior experiment
		const body: BehaviorExperimentPostRequestBody = {
			title: formValues.title,
			description: formValues.description,
			expectation: {
				negativeThought: formValues.negativeExpectation,
				negativeBelievability: formValues.negativeExpectationBelievability,
				negativeConsequence: formValues.negativeExpectationConsequence,
				rationalThought: formValues.rationalExpectation,
				rationalBelievability: formValues.rationalExpectationBelievability,
				rationalConsequence: formValues.rationalExpectationConsequence,
			},
			expiresAt: moment(formValues.expiresAt).toDate(),
			patient: userId,
			remindAt: reminderDateTime,
		};
		const behaviorExprData: BehaviorExperimentDetailResponse = yield select(getBehaviorExprDetailResponse);

		// handle if the form is editing
		if (isEditing) {
			const behaviorExprId = behaviorExprData.id;
			yield apiCallSaga(
				behaviorExprSDK.updateBehaviorExperiment,
				token,
				behaviorExprId.toString(),
				body as BehaviorExperimentPutRequestBody,
			);
		} else {
			/**
			 * Auto enable behavior experiment if it's disabled after create a new registration
			 */
			yield call(onAutoEnableCoreTracker, {
				userId,
				coreTrackerId: CoreTrackerId.BEHAVIOR_EXPERIMENT,
			});

			yield apiCallSaga(behaviorExprSDK.createBehaviorExperiment, token, body);
		}

		// reset the saved form value for the selected `userId`
		yield put(behaviorExprActions.resetFormValues(userId));
		yield put(behaviorExprActions.saveBehaviorExpr.success({userId}));
		yield put(behaviorExprActions.closeForm());
		yield put(
			toastActions.addToast({message: strTranslation.GRAPHS.new_event.plan.success.toast, type: "success"}),
		);
	} catch (error) {
		log.error("Error while saving behavior experiment data", error);
		yield put(toastActions.addToast({message: strTranslation.GRAPHS.new_event.plan.fail.toast, type: "error"}));
	}
}
