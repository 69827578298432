import {InterventionCustomTrackerListItem, InterventionCustomTrackerParams} from "@sense-os/goalie-js";
import {TrackerVersion, localiseTextFromBE} from "../../../tracker/customTracker/helpers/customTrackerHelpers";
import featureFlags from "../../../featureFlags/FeatureFlags";
import interventionCustomTrackerSDK from "./interventionCustomTrackerSDK";

/** total supported custom tracker
 * since the response is mixed default trackers & custom trackers, the page size request is updated to 100 */
export const PAGE_SIZE = 100;

/** current supported custom tracker version */
const VERSION = featureFlags.trackerV4 ? TrackerVersion.VERSION_4 : TrackerVersion.VERSION_2;

/**
 * Get Custom Tracker Summary List
 * @param token
 * @param params
 * @returns InterventionCustomTrackerListItem[]
 */
export const getCustomTrackerSummaryLists = async (token: string, params: InterventionCustomTrackerParams = {}) => {
	const customTrackerSummaryResponse = await interventionCustomTrackerSDK.getCustomTrackerSummaryList(token, {
		...params,
		pageSize: PAGE_SIZE,
		version: VERSION,
	});

	return customTrackerSummaryResponse;
};

/**
 * Transform Custom Tracker Summary
 * @param tracker
 * @returns InterventionCustomTrackerListItem
 */
export const transformCustomTrackerSummary = (
	tracker: InterventionCustomTrackerListItem,
): InterventionCustomTrackerListItem => {
	return {
		...tracker,
		iconName: tracker.sensorName,
		trackerName: localiseTextFromBE(tracker.trackerName),
	};
};

type MergeCustomTrackersByTrackerIdParam = {
	trackerId: number;
	isEnabled: boolean;
	customTrackers: InterventionCustomTrackerListItem[];
};

/**
 * Merge Existing Custom Trackers to enable/disable custom trackers by selected tracker id
 * @param param
 * @returns InterventionCustomTrackerListItem[]
 */
export const mergeCustomTrackersByTrackerId = (
	param: MergeCustomTrackersByTrackerIdParam,
): InterventionCustomTrackerListItem[] => {
	const {trackerId, isEnabled, customTrackers} = param;

	return customTrackers.map((customTracker) => {
		const isEnabledCustomTracker = customTracker.id === trackerId ? isEnabled : customTracker.isEnabled;
		return {
			...customTracker,
			isEnabled: isEnabledCustomTracker,
		};
	});
};

/** Intervention Custom Tracker Reminder button relies on `loggerReminder` &  `customTrackerReminder` feature flag */
export const isShowReminderButton = (): boolean => {
	return featureFlags.loggerReminder && featureFlags.customTrackerReminder;
};
