////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// lib imports
import {NotificationItem} from "./notificationCenter/NotificationItem";
import {TrackingState} from "./tracking/TrackingTypes";
import {ChatState} from "../../chat/redux/ChatReducer";
import {UIState} from "./UI/UIReducer";
import {VideoCallState} from "./videoCall/VideoCallState";
import {MeetingNotesState} from "../../tracker/meetingNotes/meetingNotesTypes";
import {ClientNetworkState} from "../../clientNetwork/clientNetworkTypes";
import {LoggerConfigState} from "./loggerConfig/LoggerConfigTypes";
import {UserProfileState} from "../../userProfile/UserProfileTypes";
import {SensorTargetState} from "./sensorTarget/SensorTargetTypes";
import {ClientsFeedState} from "../../clientsFeed/clientsFeedTypes";
import {ChatPresenceState} from "../../chat/redux/ChatPresenceState";
import {ContactState} from "../../contacts/contactTypes";
import {RomState} from "./rom/RomTypes";
import {UserMapChatRoomState} from "../../chat/redux/ChatRoomReducer";
import {AuthState} from "../../auth/authTypes";
import {BootstrapState} from "../../bootstrap/bootstrapTypes";
import {CustomTrackerState} from "../../tracker/customTracker/customTrackerTypes";
import {InactiveClientState} from "../../inactiveClient/inactiveClientTypes";
import {ToastState} from "../../toaster/redux/toastTypes";
import {RateCallState} from "../../rateCall/rateCallTypes";
import {UserSettings} from "../../userSettings/userSettingsTypes";
import {ConnectionState} from "../../connectionStatus/connectionStatusTypes";
import {SidebarNavState} from "../../sidebarNav/sidebarNavTypes";
import {TreatmentStatusReduxState} from "../../treatmentStatus/treatmentStatusTypes";
import {OrganizationState} from "../../organizations/organizationsTypes";
import {FormStateMap} from "redux-form";
import {RouterState} from "connected-react-router";
import {ClientsActivityState} from "../../clientActivity/clientActivityTypes";
import {PsychoEducationState} from "../../tracker/psychoEducation/psychoEducationTypes";
import {DashboardState} from "../../dashboard/redux/DashboardReducer";
import {NewFeaturePopupReduxState} from "../../newFeaturePopup/newFeaturePopupTypes";
import {NDBlogSearchState} from "../../nicedayBlogSearch/NDBlogSearchTypes";
import {PrivateNoteReduxState} from "../../privateNotes/privateNotesTypes";
import {VersionCheckState} from "../../versionCheck/redux/versionCheckReducer";
import {TimeTrackingState} from "../../timeTracking/timeTrackingTypes";
import {WarningBarState} from "../../warningBar/redux";
import {PlanActivityState} from "../../tracker/events/redux/planActivityTypes";
import {MoodChartMapState} from "../../moodChart/moodChartTypes";
import {FileSharingUploadState} from "../../fileSharing/fileSharingTypes";
import {FileSharingState} from "./../../fileSharing/fileSharingTypes";
import {TwoFAState} from "../../twoFactorAuthentication/twoFATypes";
import {CalendarState} from "../../calendar/calendarTypes";
import {BehaviorExprState} from "../../tracker/behaviorExpr/redux/behaviorExprTypes";
import {ClientNotePageState} from "../../clientNotesPage/clientNotesTypes";
import {InterventionNoteState} from "../../intervention/note/interventionNoteType";
import {TherapySessionState} from "../../tracker/therapySession/types";
import {InterventionTreatmentGoalState} from "../../intervention/treatmentGoal/interventionTreatmentGoalType";
import {DiaryEntryState} from "../../tracker/diaryEntry/dairyEntryTypes";
import {InterventionCustomTrackerState} from "../../intervention/customTracker/interventionCustomTrackerType";
import {InterventionPsychoEducationState} from "../../intervention/psychoEducation/interventionPsychoEducationType";
import {InterventionDiaryState} from "../../intervention/diary/interventionDiaryType";
import {InterventionBehaviorExperimentState} from "../../intervention/behaviorExperiment/interventionBehaviorExperimentType";
import {InterventionSessionState} from "../../intervention/session/InterventionSessionType";
import {CheckInFeelingState} from "../../tracker/checkInFeeling/checkInFeelingTypes";
import {ThoughtRecordState} from "../../tracker/thoughtRecord/thoughtRecordTypes";
import {ClientManagementState} from "../../caseload/clientManagementTypes";
import {DialogState} from "../../components/dialog/dialogTypes";
import {AssessmentState} from "../../tracker/assessment/types";
import {InterventionAssessmentState} from "../../intervention/assessment/interventionAssessmentTypes";
import {OnlineUsersState} from "../../onlineUsers/redux/onlineUsersType";
import {XaiReduxState} from "../../xai/XaiTypes";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// app imports

/**
 * Names of the fields in the application state. Arranged as strings since they need to be referenced
 * in multiple places in the codebase, not only in `AppState` itself.
 */
export enum AppStateNames {
	// A
	auth = "auth",
	assessment = "assessment",
	// B
	bootstrap = "bootstrap",
	behaviorExperiment = "behaviorExperiment",
	// C
	calendar = "calendar",
	caseload = "caseload",
	chat = "chat",
	chatPresence = "chatPresence",
	chatRoom = "chatRoom",
	clientActivityMap = "clientActivityMap",
	clientNetwork = "clientNetwork",
	clientsFeed = "clientsFeed",
	contacts = "contacts",
	customTracker = "customTracker",
	connectionStatus = "connectionStatus",
	clientNotePage = "clientNotePage",
	checkInFeeling = "checkInFeeling",
	// D
	dashboard = "dashboard",
	dialog = "dialog",
	diaryEntry = "diaryEntry",
	// E
	EditPersonalDataReducer = "EditPersonalDataReducer",
	// F
	fileSharing = "fileSharing",
	fileSharingUpload = "fileSharingUpload",
	form = "form",
	// G
	// H
	// I
	interventionBehaviorExperiment = "interventionBehaviorExperiment",
	inactiveClient = "inactiveClient",
	InterventionDiary = "interventionDiary",
	interventionNote = "interventionNote",
	interventionPsychoEducation = "interventionPsychoEducation",
	interventionTreatmentGoal = "interventionTreatmentGoal",
	interventionCustomTracker = "interventionCustomTracker",
	interventionSession = "interventionSession",
	interventionAssessment = "interventionAssessment",
	isOrgSelectorVisible = "isOrgSelectorVisible",
	// L
	loggerConfig = "loggerConfig",
	// M
	mail = "mail",
	meetingNotes = "meetingNotes",
	moodChartMap = "moodChartMap",
	// N
	newFeaturePopup = "newFeaturePopup",
	nicedayBlogSearch = "nicedayBlogSearch",
	notifications = "notifications",
	// O
	organizations = "organizations",
	onlineUsers = "onlineUsers",
	// P
	pendingInvitations = "pendingInvitations",
	planEventForm = "planEventForm",
	psychoEducation = "psychoEducation",
	planActivity = "planActivity",
	privateNotes = "privateNotes",
	// R
	rateCall = "rateCall",
	registration = "registration",
	rom = "rom",
	router = "router",
	// S
	sensorTarget = "sensorTarget",
	sidebarNav = "sidebarNav",
	// T
	therapySession = "therapySession",
	thoughtRecord = "thoughtRecord",
	timeTracking = "timeTracking",
	toastData = "toastData",
	trackingData = "trackingData",
	treatmentStatus = "treatmentStatus",
	twoFA = "twoFA",
	// U
	UI = "UI",
	userProfile = "userProfile",
	userSettings = "userSettings",
	// V
	videoCall = "videoCall",
	versionCheck = "versionCheck",
	// W
	warningBarData = "warningBarData",
	// X
	xai = "xai",
}

/**
 * Application State.
 * Contains all application states.
 */
export interface AppState {
	// A
	[AppStateNames.auth]: AuthState;
	[AppStateNames.assessment]: AssessmentState;
	// B
	[AppStateNames.bootstrap]: BootstrapState;
	[AppStateNames.behaviorExperiment]: BehaviorExprState;
	// C
	[AppStateNames.calendar]: CalendarState;
	[AppStateNames.caseload]: ClientManagementState;
	[AppStateNames.chat]: ChatState;
	[AppStateNames.chatPresence]: ChatPresenceState;
	[AppStateNames.chatRoom]: UserMapChatRoomState;
	[AppStateNames.clientActivityMap]: ClientsActivityState;
	[AppStateNames.clientNetwork]: ClientNetworkState;
	[AppStateNames.clientsFeed]: ClientsFeedState;
	[AppStateNames.contacts]: ContactState;
	[AppStateNames.customTracker]: CustomTrackerState;
	[AppStateNames.connectionStatus]: ConnectionState;
	[AppStateNames.clientNotePage]: ClientNotePageState;
	[AppStateNames.checkInFeeling]: CheckInFeelingState;
	// D
	[AppStateNames.dashboard]: DashboardState;
	[AppStateNames.dialog]: DialogState;
	[AppStateNames.diaryEntry]: DiaryEntryState;
	// E
	// F
	[AppStateNames.fileSharing]: FileSharingState;
	[AppStateNames.fileSharingUpload]: FileSharingUploadState;
	[AppStateNames.form]: FormStateMap;
	// G
	// H
	// I
	[AppStateNames.interventionBehaviorExperiment]: InterventionBehaviorExperimentState;
	[AppStateNames.inactiveClient]: InactiveClientState;
	[AppStateNames.InterventionDiary]: InterventionDiaryState;
	[AppStateNames.interventionNote]: InterventionNoteState;
	[AppStateNames.interventionPsychoEducation]: InterventionPsychoEducationState;
	[AppStateNames.interventionTreatmentGoal]: InterventionTreatmentGoalState;
	[AppStateNames.interventionCustomTracker]: InterventionCustomTrackerState;
	[AppStateNames.interventionSession]: InterventionSessionState;
	[AppStateNames.interventionAssessment]: InterventionAssessmentState;
	// L
	[AppStateNames.loggerConfig]: LoggerConfigState;
	// M
	[AppStateNames.meetingNotes]: MeetingNotesState;
	[AppStateNames.moodChartMap]: MoodChartMapState;

	// N
	[AppStateNames.newFeaturePopup]: NewFeaturePopupReduxState;
	[AppStateNames.nicedayBlogSearch]: NDBlogSearchState;
	[AppStateNames.notifications]: NotificationItem[];
	// O
	[AppStateNames.organizations]: OrganizationState;
	[AppStateNames.onlineUsers]: OnlineUsersState;
	// P
	[AppStateNames.psychoEducation]: PsychoEducationState;
	[AppStateNames.privateNotes]: PrivateNoteReduxState;
	[AppStateNames.planActivity]: PlanActivityState;
	// R
	[AppStateNames.rateCall]: RateCallState;
	[AppStateNames.rom]: RomState;
	[AppStateNames.router]: RouterState;

	// S
	// selectedClientId: number; // TODO: @boby see if this temporary state for selected client id could be merge inside `chat` state
	[AppStateNames.sensorTarget]: SensorTargetState;
	[AppStateNames.sidebarNav]: SidebarNavState;

	// T
	[AppStateNames.therapySession]: TherapySessionState;
	[AppStateNames.thoughtRecord]: ThoughtRecordState;
	[AppStateNames.timeTracking]: TimeTrackingState;
	[AppStateNames.toastData]: ToastState;
	[AppStateNames.trackingData]: TrackingState;
	[AppStateNames.treatmentStatus]: TreatmentStatusReduxState;
	[AppStateNames.twoFA]: TwoFAState;

	// U
	[AppStateNames.UI]: UIState;
	[AppStateNames.userProfile]: UserProfileState;
	[AppStateNames.userSettings]: UserSettings;

	// V
	[AppStateNames.videoCall]: VideoCallState;
	[AppStateNames.versionCheck]: VersionCheckState;

	// W
	[AppStateNames.warningBarData]: WarningBarState;

	// X
	[AppStateNames.xai]: XaiReduxState;
}
